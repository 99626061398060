import { BookingsData, createSingleBooking, createMultiBooking } from "../../../utils/apis/bookings_repository";
import { Text } from "../Text"
import { DatePicker } from "../../ui/inputs/DatePicker";
import { getDateTimeString, prettifyTime } from "../../../utils/helper";
import { useState } from "react";
import { Button } from "../CustomButton";
import { TimePicker } from "../inputs/TimePicker";
import { DatePickerWithRange } from "../inputs/DateRangePicker";
import { SheetClose } from "../Sheets";
import { UserModel } from "@/utils/apis/user_repository";
import { useQuery } from "@tanstack/react-query";
import { getAllUsers } from "../../../utils/apis/user_repository";
import { Spinner } from "../Spinner";
import { getUserFromLocalStorage, getTimeFromDate } from "../../../utils/helper";
import { Autocomplete, Divider, TextField } from "@mui/material";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { DateTime } from "luxon";
import { showToast } from "../../../utils/toast";
import { getTenant } from "../../../utils/apis/tenants_repository";

interface CreateBookingBodyProps {
    setDialogState: (state: boolean) => void;
}

const CreateBookingBody = ({ setDialogState }: CreateBookingBodyProps) => {

    const currentUser = getUserFromLocalStorage();

    const { data: tenantData } = useQuery({
        queryKey: ['tenantData'],
        queryFn: () => getTenant(currentUser.tenant),
    })

    // tenantData can be undefined
    // tenantData.fulldayDefaultTimes.startHour = 0800. we need to return only 8
    const tenantStartHour = tenantData?.fulldayDefaultTimes.startHour ? tenantData.fulldayDefaultTimes.startHour / 100 : 9;
    const tenantEndHour = tenantData?.fulldayDefaultTimes.endHour ? tenantData.fulldayDefaultTimes.endHour / 100 : 18;
    const prettyStartHour = prettifyTime(tenantData?.fulldayDefaultTimes.startHour ? tenantData?.fulldayDefaultTimes.startHour : 800);
    const prettyEndHour = prettifyTime(tenantData?.fulldayDefaultTimes.endHour ? tenantData?.fulldayDefaultTimes.endHour : 1800);
    ;
    const [selectedUser, setSelectedUser] = useState<UserModel>({ userId: currentUser.userId, name: currentUser.name, email: currentUser.email, role: currentUser.role, tenant: currentUser.tenant, bookingLimit: 0, futureBookingPeriod: 30, lastMinuteBookingPeriod: 1, cardholderId: "", gallagherId: "", vehicles: [], permanentParking: false });
    const [bookingType, setBookingType] = useState("Single");
    const [startTime, updateStartTime] = useState<string>(
        // check if the current time is less than 9 am and set the time accordingly
        // DateTime.now().hour < 9 ? "09:00 AM" : getTimeFromDate(new Date())
        DateTime.now().hour < tenantStartHour ? prettyStartHour : getTimeFromDate(new Date())
    );
    const [endTime, updateEndTime] = useState<string>(
        // check if the start time is less than 9 am and set the time accordingly
        // DateTime.now().hour < 16 ? "06:00 PM" : getTimeFromDate(DateTime.now().plus({ hours: 3 }).toJSDate())
        DateTime.now().hour < tenantEndHour ? prettyEndHour : getTimeFromDate(DateTime.now().plus({ hours: 3 }).toJSDate())
    );

    const [userId, setUserId] = useState<string>(currentUser.userId);

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const queryClient = useQueryClient();

    const [startDate, setStartDate] = useState<Date>(
        DateTime.now().toJSDate()
    );
    const [endDate, setEndDate] = useState<Date>();

    const handleClickMulti = () => {
        setBookingType("Multi");
        setStartDate(DateTime.now().toJSDate());
        setEndDate(DateTime.now().plus({ days: 2 }).toJSDate());
    }

    const handleUserSelect = (userId: string) => {
        setUserId(userId);
    }

    const handleClickSingle = () => {
        setBookingType("Single");
        setStartDate(DateTime.now().toJSDate());
    }

    const handleStartTime = (time: string) => {
        updateStartTime(time);
    }

    const handleEndTime = (time: string): void => {
        updateEndTime(time);
    }

    const handleStartDate = (date: Date) => {

        // check if the date is not today, change the start time to 9 am
        if (date.getDate() !== DateTime.now().day) {
            updateStartTime(prettyStartHour);
            updateEndTime(prettyEndHour);
        } else {
            // check if the current time is less than 9 am and set the time accordingly
            updateStartTime(DateTime.now().hour < tenantStartHour ? prettyStartHour : getTimeFromDate(new Date()));
            updateEndTime(DateTime.now().hour < tenantEndHour - 2 ? prettyEndHour : getTimeFromDate(DateTime.now().plus({ hours: 3 }).toJSDate()));
        }

        setStartDate(date);
    }

    const handleEndDate = (date: Date) => {
        setEndDate(date);
    }

    const createSingleBookingMutation = useMutation({
        mutationFn: createSingleBooking,
        onSuccess: async (value) => {
            if (value) {
                queryClient.invalidateQueries({ queryKey: ['bookings'] });
                setDialogState(false);
                setIsLoading(false);
            } else {
                setIsLoading(false);
            }
        },
    })

    const createMultiBookingMutation = useMutation({
        mutationFn: createMultiBooking,
        onSuccess: (value) => {
            if (value) {
                queryClient.invalidateQueries({ queryKey: ['bookings'] });
                setDialogState(false);
                setIsLoading(false);
            } else {
                setIsLoading(false);
            }
        },
    })

    const handleCreateBooking = async () => {

        const now = DateTime.now();

        if ((!startDate || !endDate) && bookingType === "Multi") {
            showToast("One or more date fields are empty", {
                type: "warning"
            });
            return;
        }

        if (!startDate && bookingType === "Single") {
            showToast("Start date is empty", {
                type: "warning"
            });
            return;
        }

        if (!startTime) {
            showToast("Start time is empty", {
                type: "warning"
            });
            return;
        }

        if (!endTime) {
            showToast("End time is empty", {
                type: "warning"
            });
            return;
        }

        if (!userId) {
            showToast("Select a user to make a booking", {
                type: "warning"
            });
            return;
        }

        const updatedStartDate = getDateTimeString(startDate!.toISOString(), startTime!);
        const updatedEndDate = getDateTimeString(bookingType === "Single" ? startDate!.toISOString() : endDate!.toISOString(), endTime!);


        if (DateTime.fromJSDate(new Date(updatedStartDate)) < now) {
            showToast("Start time cannot be in the past", {
                type: "warning"
            });
            return;
        }


        if (DateTime.fromJSDate(new Date(updatedStartDate)) > DateTime.fromJSDate(new Date(updatedEndDate))) {
            showToast("Start date cannot be after than the end date", {
                type: "warning"
            });
            return;
        }

        const boookingData: Partial<BookingsData> = {
            bookingType: bookingType,
            startTime: updatedStartDate,
            endTime: updatedEndDate,
            userId: userId!
        }
        setIsLoading(true);
        bookingType === "Single" ? createSingleBookingMutation.mutate(boookingData) : createMultiBookingMutation.mutate(boookingData);
    }

    const userData = getUserFromLocalStorage();

    const { data: allUsers, isLoading: dataLoading, isError } = useQuery({
        queryKey: ['users'],
        queryFn: () => getAllUsers(
            userData.role === "SuperAdmin" ? undefined : userData.tenant
        ),
    })

    if (isError) {
        return <div className="flex justify-center">Something went wrong</div>
    }

    return (
        <>
            {dataLoading ? <div className="flex items-center flex-col justify-center">
                <Text as="span">Getting all User Details, Please wait</Text>
                <div className="mt-4">
                    <Spinner />
                </div>
            </div> : <div>
                <div className="flex pt-10 flex-row items-center justify-between">
                    <Text as="h2">Create New Booking</Text>
                </div>
                <div className="pt-4">
                    <Divider className="bg-secondaryText" />
                </div>
                <Text as="h3">Booking Type</Text>
                <div className="flex flex-row space-x-4 mt-6">
                    <Button onClick={handleClickSingle} variant={bookingType === "Single" ? "default" : "secondary"} className={`w-44 border-primary ${bookingType === "Single" ? "text-white" : "border-primary text-primaryText"}`}>Single Booking</Button>
                    <Button onClick={handleClickMulti} variant={bookingType === "Multi" ? "default" : "secondary"} className={`w-44 ${bookingType === "Multi" ? "text-white" : "border-primary text-primaryText"}`}>Multi Booking</Button>
                </div>
                <Text className="mt-10" as="h3">Booking Date</Text>
                <div className="mt-6">
                    {bookingType === "Single" ? <DatePicker setNewDate={(value) => handleStartDate(value)} initialDate={startDate} /> : <DatePickerWithRange setStartDate={(val) => handleStartDate(val)} setEndDate={(val) => handleEndDate(val)} startDate={startDate!} endDate={endDate!} />}
                </div>
                <div className="mt-4 flex flex-row">
                    <div className="flex flex-col">
                        <Text as="h3">Start Time</Text>
                        <div className="mt-6">
                            <div className="flex flex-row space-x-4">
                                <TimePicker initialTime={startTime} onChange={handleStartTime} />
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col ml-6">
                        <Text as="h3">End Time</Text>
                        <div className="mt-6 ">
                            <div className="flex flex-row space-x-4">
                                <TimePicker initialTime={endTime} onChange={handleEndTime} />
                            </div>
                        </div>
                    </div>
                </div>
                <Text className="mt-10" as="h3">Select User</Text>

                {/* <div className="flex mt-6 items-center">{
                    <ComboboxDemo allValues={allUsers!} initialValue={userId} setNewValue={(val) => handleUserSelect(val)} />
                }</div> */}

                <div className="mt-10">
                    <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        options={allUsers!.map((user) => user.name)}
                        // disable options for vip users
                        getOptionDisabled={(option) => {
                            return allUsers!.find((user) => user.name === option)?.permanentParking!;
                        }}
                        value={selectedUser?.name}
                        onChange={(event, value) => {
                            setSelectedUser(allUsers!.find((user) => user.name === value)!);
                            handleUserSelect(allUsers!.find((user) => user.name === value)?.userId!);
                        }}
                        sx={{ width: 300 }}
                        renderInput={(params) => <TextField {...params} label="Select Users" />}
                    />
                </div>
                <div className="mt-6 py-3 px-6 inline-block bg-primary bg-opacity-40 text-primary rounded-md">
                    <Text as="span" className="mt-2 text-status-approved-full">Booking not allowed for VIP users</Text>
                </div>

                <div className="absolute bottom-0 mx-12 right-0 mb-10 flex flex-row justify-end">
                    <SheetClose asChild>
                        <Button variant={"secondary"} className="w-44 border border-primary text-primaryText">Cancel</Button>
                    </SheetClose>
                    <Button variant={"default"} onClick={handleCreateBooking} isLoading={isLoading} className="w-44 ml-4 text-white">Save Changes</Button>
                </div>
            </div>}
        </>
    );
}

export default CreateBookingBody;