import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import { Text } from '../../components/ui/Text';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import StripeContainer from '../../components/ui/containers/StripeContainer';
import { UserModel, createValetUser } from '../../utils/apis/user_repository';
import { createValetBooking } from '../../utils/apis/bookings_repository';
import ReCAPTCHA from 'react-google-recaptcha';
import vip_logo from "../../assets/images/vip_logo.png";
import { getTenant } from '../../utils/apis/tenants_repository';

export const getTimeNowInAussie = (): Date => {
    const date = new Date();
    const options: Intl.DateTimeFormatOptions = {
        timeZone: 'Australia/Sydney',
        hour12: false,
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
    };
    const formatter = new Intl.DateTimeFormat('en-GB', options);
    const parts = formatter.formatToParts(date);

    const aussieDateParts = parts.reduce((acc, part) => {
        acc[part.type] = part.value;
        return acc;
    }, {} as Record<string, string>);

    const aussieTime = new Date(
        `${aussieDateParts.year}-${aussieDateParts.month}-${aussieDateParts.day}T${aussieDateParts.hour}:${aussieDateParts.minute}:${aussieDateParts.second}`
    );

    return aussieTime;
};

interface Props { }

const Payment: React.FC<Props> = () => {
    const [showItem, setShowItem] = useState<boolean>(false);
     const [selectedDate, setSelectedDate] = useState<Date | null>(null);
    const [isDateAvailable, setIsDateAvailable] = useState<boolean>(true);
    const [loading, setLoading] = useState<boolean>(false);
    const [venue, setVenue] = useState<string>('');
    const [amount, setAmount] = useState<number>(0);
    const [phone, setPhone] = useState<string>('');
    const [bookingLoading, setBookingloading] = useState<boolean>(false);
    const [status, setStatus] = useState<boolean>(false);
    const [recaptchaToken, setRecaptchaToken] = useState<string | null>(null);
    const [startHour, setStartHour] = useState<number | null>(null);
    const [endHour, setEndHour] = useState<number | null>(null);
    const recaptchaRef = useRef<ReCAPTCHA>(null);
    const [valetUser, setValetUser] = useState<UserModel>({
        cardholderId: '',
        gallagherId: '',
        email: '',
        name: '',
        vehicles: [],
        role: '',
        tenant: '',
        permanentParking: false,
        userId: '',
        bookingLimit: 0,
        futureBookingPeriod: 0,
        lastMinuteBookingPeriod: 0,
    });
    const [plateNumber, setPlateNumber] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [name, setName] = useState<string>('');
    const [bookingId, setBookingId] = useState<string | null>(null);
    // const [time, setCurrentTime] = useState<Date>(getTimeNowInAussie());

    useEffect(() => {
        setValetUser({
            ...valetUser,
            name,
            email,
            vehicles: [plateNumber],
        });
    }, [email, plateNumber, name]);

    // const formatDateForPayload = (dateString: string): string => {
    //     const date = new Date(dateString);
    //     const hours = date.getHours();
    //     const minutes = date.getMinutes();
    //     const seconds = date.getSeconds();
    //     const datePart = date.toISOString().split('T')[0]; // Extract date in YYYY-MM-DD format
    //     const timePart = `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}.000`; // Create time string in HH:MM:SS.sss format
    //     return `${datePart}T${timePart}Z`; // Combine date and time in the required format
    // };
const formatDateForPayload = (date: Date): string => {
    // Convert the date to UTC to prevent timezone issues
    const utcDate = new Date(Date.UTC(
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
        date.getHours(),
        date.getMinutes(),
        date.getSeconds()
    ));

    const datePart = utcDate.toISOString().split('T')[0]; // Extract date in YYYY-MM-DD format
    const timePart = '00:00:00.000'; // Ensure time is midnight in UTC
    return `${datePart}T${timePart}Z`; // Combine date and time
};

    const checkDateAvailability = async (date: any): Promise<boolean> => {
        const formattedDate = formatDateForPayload(date);
        console.log("formatted date ", formattedDate)

        try {

            const response = await axios.post(`${process.env.REACT_APP_TENANT_OCCUPANCY_URL}`, {
                date: formattedDate,
            });
            return response.data.status === true;
        } catch (error) {
            console.error('Error fetching date availability', error);
            return false;
        }
    };

    const fetchAmountForVenue = (venue: string): number => {
        switch (venue) {
            case '12 Micron':
                return 80;
            case 'Meat & Wine Co':
                return 80;
            case 'Nola':
                return 80;
            case 'Callao':
                return 80;
            case "The Butcher's Block":
                return 80;
            default:
                return 0;
        }
    };

    const handleDateChange = async (date: Date): Promise<void> => {
        setSelectedDate(date);
        setLoading(true);
        const isAvailable = await checkDateAvailability(date);
        setIsDateAvailable(isAvailable);
        setLoading(false);
    };

    const handleBookClick = async (): Promise<void> => {
        // if (recaptchaRef.current) {
        //     recaptchaRef.current.execute(); 
        // }
        // console.log("in book", recaptchaToken);
        // const SECRET_KEY_V3 = "6LdE_icqAAAAAJL-8Awpz9f2cIucEkTD6C5vwyLb";
        // const recaptcahResponse = await axios.post(`https://www.google.com/recaptcha/api/siteverify?secret=${SECRET_KEY_V3}&response=${recaptchaToken}`)
        // console.log(recaptcahResponse.data);
        setBookingloading(true);

        if (!isDateAvailable || !phone || !email || !venue || !name) {
            alert('Please fill out all fields and ensure the selected date is available.');
            return;
        }

        let bookingStartTime = new Date(selectedDate!);
        bookingStartTime.setHours(0, 0, 0, 0);

        const aussieTimeNow = getTimeNowInAussie();
        // console.log(aussieTimeNow)
        const isToday = aussieTimeNow.toDateString() === bookingStartTime.toDateString();
        let bookingTime = '';
        if (isToday && aussieTimeNow.getHours() >= 12) {
            // Extract time part in HH:MM:SS format
            const hours = aussieTimeNow.getHours().toString().padStart(2, '0');
            const minutes = aussieTimeNow.getMinutes().toString().padStart(2, '0');
            const seconds = aussieTimeNow.getSeconds().toString().padStart(2, '0');
            bookingTime = `${hours}:${minutes}:${seconds}`;
        }

        const valetUserId = await createValetUser(valetUser);

        // const bookingId = getUniqueBookingId();
        const booking = {
            userId: valetUserId,
            name: name,
            plateNumber: plateNumber,
            startTime: selectedDate!.toString(),
            endTime: selectedDate!.toString(),
            email: email,
            // bookingId: bookingId,
            bookingStartTime: bookingTime,
            phone: phone,
            venue: venue,
        };

        try {
            const { bookingId, isApproved } = await createValetBooking(booking);
            if (!bookingId) {
                console.error("Booking creation failed");
                setBookingloading(false);
                return;
            } else {
                setBookingId(bookingId);
                setStatus(isApproved);
                setBookingloading(false);
                setShowItem(true);
            }
        } catch (error) {
            console.error("Error creating booking:", error);
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            setAmount(await fetchAmountForVenue(venue));
    
            const valetTen = await getTenant("VALET"); 
            console.log(valetTen);
    
            if (valetTen && valetTen.fulldayDefaultTimes) {
                const { startHour, endHour } = valetTen.fulldayDefaultTimes;
                setStartHour(startHour);
                setEndHour(endHour);
                console.log(`Start Hour: ${startHour}, End Hour: ${endHour}`);
            }
        };
    
        fetchData();
    }, [venue]);
    
    const formatTime = (time: any) => {
        const hour = Math.floor(time / 100); 
        const minutes = time % 100; 
        const ampm = hour >= 12 ? 'PM' : 'AM';
        const formattedHour = hour % 12 === 0 ? 12 : hour % 12; 

        return `${formattedHour}:${minutes.toString().padStart(2, '0')} ${ampm}`;
    };

    useEffect(() => {
        const checkInitialDateAvailability = async () => {
            const aussieTimeNow = getTimeNowInAussie();
            const todayDay = aussieTimeNow.getDay();

            if (todayDay === 5 || todayDay === 6) {
                setSelectedDate(aussieTimeNow);
                handleDateChange(aussieTimeNow);


            }
        };

        checkInitialDateAvailability();
    }, []);


    const handlePhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value.replace(/\D/g, '');
        setPhone(newValue);
    };

    const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value.trim().toLowerCase();
        setEmail(newValue);
    };

    const validateEmail = (email: string): boolean => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const isFormValid = () => {
        return isDateAvailable && venue && phone && email && validateEmail(email) && name;
    };
    const handleRecaptchaChange = (token: any) => {
        console.log("handleRecaptchaChange triggered");
        if (token) {
            console.log("ReCAPTCHA token:", token);
        } else {
            console.log("No token received");
        }
    };

    const handleButtonClick = () => {
        console.log("Button clicked, executing reCAPTCHA");
        if (recaptchaRef.current) {
            recaptchaRef.current.execute();
        } else {
            console.log("recaptchaRef is null");
        }
    };

    const getAllowedDays = () => {
        const days = process.env.REACT_APP_ALLOWED_DAYS || "5,6"; 
        return days.split(',').map(Number);
    };
    
    const allowedDays = getAllowedDays();
    return (
        <div className={showItem ? 'w-full h-screen flex items-center justify-center' : 'w-full h-screen flex items-center justify-center'}>
            {showItem ? (
                <StripeContainer
                    selectedDate={selectedDate!}
                    venue={venue}
                    phone={phone}
                    plateNumber={plateNumber}
                    email={email}
                    bookingId={bookingId!}
                    status={status}
                    startHour = {formatTime(startHour)}
                    endHour = {formatTime(endHour)}
                />
            ) : (
                <div className="valetCard">
                    <div className="bg-white flex flex-col items-center justify-center">
                        <img src={vip_logo} style={{ height: '150px' }} />
                        <Text as="h2" className="text-xl  justify-center font-bold text-gray-600 mt-0 mb-4">
                        Create Booking
                    </Text>
                        <p className="text-sm text-center mt-2 px-4 text-gray-600 max-w-l mb-6 text-justify">
                            For a hassle-free dining experience, choose VIP Parking.
                            Simply book your slot, access the car park via 3 Watermans Quay, and we will take care of the rest.
                            <br/><br/>
                            VIP Parking is available to book from <b>{startHour && formatTime(startHour)}</b> to <b>{endHour && formatTime(endHour)}</b> on <b>Friday</b> and <b>Saturday</b> evenings.
                            For best experience please add your number plate to automate the opening of the roller shutter and boom gates.
                            <br/><br/>
                            Note the carpark height is <b>2.2m</b>
                        </p>

                        {/* Venue Selection and Price */}
                        <div 
                        className="venue-selection flex mb-4 items-center justify-center w-64">
                            <select
                                value={venue}
                                onChange={(e) => setVenue(e.target.value)}
                                className="border-input ring-offset-background focus:outline-blue-500 placeholder:text-muted-foreground h-12 w-3/4 rounded-md border hover:border-2 focus-visible:outline-blue-500 bg-transparent px-4 py-2 text-md disabled:cursor-not-allowed disabled:opacity-50 border-gray-400"
                            >
                                <option value="" disabled>Select a venue</option>
                                <option value="12 Micron">12 Micron</option>
                                <option value="Meat & Wine Co">Meat & Wine Co</option>
                                <option value="Nola">Nola</option>
                                <option value="Callao">Callao</option>
                                <option value="The Butcher's Block">The Butcher's Block</option>
                            </select>

                            <div className="w-1/4 ml-2 flex items-center justify-center bg-gray-100 border border-gray-400 h-12 rounded-md">
                                <span className="text-lg font-bold text-gray-700">$80</span>
                            </div>
                        </div>


                        <DatePicker
                            minDate={getTimeNowInAussie()}
                            selected={selectedDate}
                            onChange={handleDateChange}
                            dateFormat="dd/MM/yyyy"
                            className="date-picker mb-4 px-4 border-input items-center justify-center ring-offset-background focus:outline-blue-500 placeholder:text-muted-foreground flex h-12 w-64 rounded-md border hover:border-2 disabled:opacity-50 border-gray-400"
                            filterDate={(date) => {
                                const day = date.getDay(); 
                                return allowedDays.includes(day);
                              }}
                              placeholderText={getTimeNowInAussie().getDay() === 5 || getTimeNowInAussie().getDay() === 6 ? "Select a Date" : "Date*"}
                        />

                        {isDateAvailable && !loading && (
                            <>
                                <input
                                    className="date-picker mb-4 px-4 border-input items-center justify-center ring-offset-background focus:outline-blue-500 placeholder:text-muted-foreground flex h-12 w-64 rounded-md border hover:border-2 disabled:opacity-50 border-gray-400"
                                    type="text"
                                    placeholder="Name*"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                    required
                                />
                                <input
                                    className="date-picker mb-4 px-4 border-input items-center justify-center ring-offset-background focus:outline-blue-500 placeholder:text-muted-foreground flex h-12 w-64 rounded-md border hover:border-2 disabled:opacity-50 border-gray-400"
                                    type="email"
                                    placeholder="Email*"
                                    value={email}
                                    onChange={handleEmailChange}
                                    required
                                />
                                <input
                                    className="date-picker mb-4 px-4 border-input items-center justify-center ring-offset-background focus:outline-blue-500 placeholder:text-muted-foreground flex h-12 w-64 rounded-md border hover:border-2 disabled:opacity-50 border-gray-400"
                                    type="tel"
                                    placeholder="Phone*"
                                    value={phone}
                                    onChange={handlePhoneChange}
                                    maxLength={10}
                                    required
                                />
                                <input
                                    className="date-picker mb-4 px-4 border-input items-center justify-center ring-offset-background focus:outline-blue-500 placeholder:text-muted-foreground flex h-12 w-64 rounded-md border hover:border-2 disabled:opacity-50 border-gray-400"
                                    type="text"
                                    placeholder="Plate Number"
                                    value={plateNumber}
                                    onChange={(e) => {
                                        const filteredValue = e.target.value.replace(/[^a-zA-Z0-9]/g, '').toUpperCase();
                                        setPlateNumber(filteredValue);
                                    }}
                                    pattern="[A-Za-z0-9]*"
                                />

                            </>
                        )}

                        {loading ? (
                            <div className="loader">Checking availability...</div>
                        ) : (
                            <div>
                                {/* <button onClick={handleClick}>Submit</button> */}
                                <div>
                                    <ReCAPTCHA
                                        ref={recaptchaRef}
                                        sitekey="6Lfu9ycqAAAAANXbdQhb53RqlWe-Tcxepl_9vvFU"
                                        onChange={handleRecaptchaChange}
                                        size='invisible'
                                    />
                                </div>
                                <button
                                    onClick={handleBookClick}
                                    disabled={!isFormValid()}
                                    className="w-48 flex justify-center bg-primary h-12 py-2 px-4 text-white font-medium rounded-md disabled:cursor-not-allowed disabled:opacity-50 items-center justify-center"
                                >
                                    {bookingLoading ? (
                                        <svg className="animate-spin h-5 w-5 mr-3 text-white" viewBox="0 0 24 24">
                                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8v8z"></path>
                                        </svg>
                                    ) : (
                                        "Book"
                                    )}
                                </button>
                            </div>
                        )}

                        {!isDateAvailable && <p className="error-message mt-2 text-primary">Selected date is occupied. Please choose another date.</p>}
                    </div>
                </div>
            )}
        </div>
    );
};

export default Payment;
