import React from "react";
import { useState } from "react";
import { BookingDataColumn } from "../../components/ui/table/columns/BookingColumn";
import { useQuery } from '@tanstack/react-query';
import CustomTable from "../../components/ui/CustomTable";
import { getBookings } from "../../utils/apis/bookings_repository";
import { Text } from "../../components/ui/Text";
import { Spinner } from "../../components/ui/Spinner";
import { getUserFromLocalStorage } from "../../utils/helper";
import { Button } from "../../components/ui/CustomButton";
import { Sheet } from "../../components/ui/Sheets";
import CreateBookingBody from "../../components/ui/sheets/CreateBooking";
import CreateComplimentaryValetBookingBody from "../../components/ui/sheets/CreateComplimentaryValetBooking";

const BookingsBody = () => {

    const user = getUserFromLocalStorage();
    const [openDialog, setOpenDialog] = useState(false);
    const [openComplimentary, setOpenComplimentary] = useState(false);

    const columns = React.useMemo(() => BookingDataColumn(), []);
    const { data: bookings, isLoading: dataLoading, isError } = useQuery({
        queryKey: ['bookings'],
        queryFn: () => getBookings(
            user.role === "SuperAdmin" ? undefined : user.tenant
        ),
    });

    const handleOpenDialog = (val: boolean) => {
        setOpenDialog(val);
    };

    if (isError) {
        return <div className="flex justify-center">Something went wrong</div>;
    }

    console.log(bookings)
    const filteredBookings = bookings?.filter(booking =>
        !(booking.tenant === 'VALET' && !booking.paymentId)
    ) || [];

    return (
        <>
            <div className={filteredBookings.length === 0 ? 'w-full h-screen flex items-center justify-center px-14' : 'px-14 w-full'}>
                {dataLoading || bookings === undefined ? <Spinner /> : filteredBookings.length === 0 ? (
                    <div className="flex flex-col items-center">
                        <Text as="h3" className="text-center">No Bookings found</Text>
                        <>
                            {user.tenant === 'VALET' && user.role === 'TenantAdmin' ?
                                <Button onClick={() => setOpenComplimentary(true)}>Complimentary</Button>
                                :
                                <Button onClick={() => handleOpenDialog(true)} className="w-48 mt-4 flex justify-center">Create Booking</Button>
                            }
                            <Sheet open={openDialog} onOpenChange={(val) => handleOpenDialog(val)} children={<CreateBookingBody setDialogState={(val) => setOpenDialog(val)} />} />
                            <Sheet open={openComplimentary} onOpenChange={(val) => setOpenComplimentary(val)} children={<CreateComplimentaryValetBookingBody setDialogState={(val) => setOpenComplimentary(val)} />} />
                        </>
                    </div>
                ) : (
                    <CustomTable title={"Bookings"} columns={columns} data={filteredBookings} count={7} />
                )}
            </div>
        </>
    );
};

export default BookingsBody;
