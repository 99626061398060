import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate} from 'react-router-dom';
import { Text } from '../../components/ui/Text';
import axios from 'axios';
import {  getBookingDetailsFromHistory, getValetBookingDetails } from '../../utils/apis/bookings_repository';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { handleValetCancelBooking } from '../../utils/apis/payment_repository';
import vip_logo from "../../assets/images/vip_logo.png";
const CancelBooking = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const bookingId = queryParams.get('bookingId');
    const navigate = useNavigate();
    
    const [email, setEmail] = useState<string>('');
    const [phone, setPhone] = useState<string>('');
    const [venue, setVenue] = useState<string>('');
    const [plate, setPlate] = useState<string | null>(null);
    const [date, setDate] = useState<string>('');
    const [status, setStatus] = useState<boolean>(false);
    const [bookingMessage, setBookingMessage] = useState<string | null>(null);
    const [cancelingBooking, setCancelingBooking] = useState<boolean>(false);

    useEffect(() => {
        fetchBookingDetails();
    }, [bookingId]);

    const fetchBookingDetails = async () => {
        try {
            const bookingData = await getValetBookingDetails(bookingId!);
            setBookingData(bookingData);
        } catch (error) {
            console.error('Error fetching booking details from bookings:', error);
            try {
                const bookingData = await getBookingDetailsFromHistory(bookingId!);
                if (bookingData) {
                    setBookingMessage('Your booking has already been completed or deleted. Please make a new booking.');
                }
            } catch (historyError) {
                console.error('Error fetching booking details from history:', historyError);
                setBookingMessage('An error occurred while fetching booking details. Please try again later.');
            }
        }
    };

    const setBookingData = (bookingData: any) => {
        const {  venue, phone, email, status, plate, startTime  } = bookingData;
        setPhone(phone);
        setEmail(email);
        setVenue(venue);
        setPlate(plate);
        const formattedDate = new Date(startTime).toLocaleDateString();
        setDate(formattedDate);
        setStatus(status === "Approved");
    };

    const handleEditBooking = async () => {
        navigate(`/valet/edit?bookingId=${bookingId}`)
}
const confirmCancelBooking = () => {
    confirmAlert({
        message: 'Are you sure you want to cancel this booking?',
        buttons: [
            {
                label: 'No',
            },
            {
                label: 'Yes',
                onClick: handleCancelBooking,
            },
        ],
    });
};

const handleCancelBooking = async () => {
    setCancelingBooking(true);
    try {
        const isCancelSuccess = await handleValetCancelBooking(bookingId!);

        if (isCancelSuccess) {
            navigate("/valet")
        }

    } catch (error) {
        console.error('Error canceling booking:', error);
    }
    setCancelingBooking(false);
};

    return (
        <div className='w-full h-screen flex items-center justify-center'>
            <div className="valetCard">
                <div className="bg-white flex flex-col items-center justify-center">
                   
                <img src={vip_logo} style={{ height: '150px' }} />
                   
                {bookingMessage ? (
                        <div className="text-center text-red-600 font-medium">
                            <p>{bookingMessage}</p>
                            <button
                                onClick={() => navigate("/valet")}
                                className="w-48 bg-primary h-12 py-2 px-4 text-white font-medium rounded-md mt-4"
                            >
                                Make a New Booking
                            </button>
                        </div>
                    ) : 
                     (
                        <> <Text as="h2" className="text-xl  justify-center font-bold text-gray-600 mt-0 mb-4">
                        Cancel Booking 
                    </Text>

                    <div className="booking-details mb-4">
                        <p><b>Email:</b> {email}</p>
                        <p><b>Phone: </b>{phone}</p>
                        <p><b>Plate Number:</b> {plate}</p>
                        <p><b>Venue:</b> {venue}</p>
                        <p><b>Date:</b> {date}</p>
                    </div>

                    <div className="flex space-x-4 mt-4">
                       { status ? <><button
                            onClick={handleEditBooking}
                            className="w-48 flex justify-center bg-primary h-12 py-2 px-4 text-white font-medium rounded-md disabled:cursor-not-allowed disabled:opacity-50"
                        >
                            Edit Booking
                        </button>
                        <button
                            onClick={confirmCancelBooking}
                            className="w-48 bg-red-600 h-12 py-2 px-4 text-white font-medium rounded-md flex items-center justify-center"
                        >
                          {cancelingBooking ? (
                                                        <svg className="animate-spin h-5 w-5 mr-3 text-white" viewBox="0 0 24 24">
                                                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                                            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8v8z"></path>
                                                        </svg>
                                                    ) : (
                                                        "Cancel Booking"
                                                    )}
                        </button></>:<></>}
                    </div>
                    </>
                       )}
                </div>
          
          
       </div>
        </div>
    );
};

export default CancelBooking;
