import React, { useState, useMemo } from 'react';
import { useTable, useGlobalFilter, useFilters, useSortBy, usePagination } from 'react-table';
import GlobalFilter from './table/GlobalFilter';
import { TableButton, PageButton } from './table/TableButtons';
import { Button } from './CustomButton';
import { Sheet } from './Sheets';
import InviteUsers from './sheets/InviteUsers';
import CreateBookingBody from './sheets/CreateBooking';
import CreateUserGroupBody from './sheets/body/UserGroupsComponents/CreateUserGroup';
import { Dialog, DialogContent } from "./Dialog";
import { Text } from "./Text";
import DatePicker from 'react-datepicker';
import { Autocomplete, TextField } from '@mui/material';
import CreateComplimentaryValetBookingBody from './sheets/CreateComplimentaryValetBooking';
import { getUserFromLocalStorage } from '../../utils/helper';

interface CustomTableProps {
    columns: any[]; // Adjust the type based on your actual column type
    data: any[]; // Adjust the type based on your actual data type
    count: number;
    title: string;
}

export default function CustomTable({ columns, data, count, title }: CustomTableProps) {

    const [openDialog, setOpenDialog] = useState(false);
    const [openComplimentary, setOpenComplimentary] = useState(false);
    const [startTime, setStartTime] = useState<Date | null>(null);
    const [endTime, setEndTime] = useState<Date | null>(null);
    const [bookingType, setBookingType] = useState<string | null>(null);
    const [bookingStatus, setBookingStatus] = useState<string | null>(null);

    const [appliedStartTime, setAppliedStartTime] = useState<Date | null>(null);
    const [appliedEndTime, setAppliedEndTime] = useState<Date | null>(null);
    const [appliedBookingType, setAppliedBookingType] = useState<string | null>(null);
    const [appliedBookingStatus, setAppliedBookingStatus] = useState<string | null>('');

    const loggedInUser = getUserFromLocalStorage();

    const handleStartTime = (time: Date) => {
        setStartTime(time);
    };

    const handleEndTime = (time: Date) => {
        setEndTime(time);
    };

    const handleBookingType = (type: string) => {
        setBookingType(type);
    };

    const handleBookingStatus = (status: string) => {
        setBookingStatus(status);
    };

    const filteredData = useMemo(() => {
        return data.filter(row => {
            // Apply date filter
            const rowDate = new Date(row.startTime);
            if (appliedStartTime && rowDate < appliedStartTime) return false;
            if (appliedEndTime && rowDate > appliedEndTime) return false;

            // Apply booking type filter
            if (appliedBookingType && row.bookingType !== appliedBookingType) return false;

            // Apply booking status filter
            if (appliedBookingStatus && row.status !== appliedBookingStatus) return false;

            return true;
        });
    }, [data, appliedStartTime, appliedEndTime, appliedBookingType, appliedBookingStatus]);

    const {
        state,
        preGlobalFilteredRows,
        setGlobalFilter,
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
    } = useTable(
        {
            columns,
            data: filteredData,
            initialState: {
                pageSize: count,
                sortBy: [
                    {
                        id: 'startTime',
                        desc: true,
                    },
                ],
            },
        },
        useFilters,
        useGlobalFilter,
        useSortBy,
        usePagination
    );

    const handleOpenDialog = (val: boolean) => {
        setOpenDialog(val);
    };

    const handleClearAll = () => {
        setStartTime(null);
        setEndTime(null);
        setBookingType(null);
        setBookingStatus('');
        setAppliedStartTime(null);
        setAppliedEndTime(null);
        setAppliedBookingType(null);
        setAppliedBookingStatus('');
        handleOpenDialog(false);
    };
    const handleApplyFilters = () => {
        setAppliedStartTime(startTime);
        setAppliedEndTime(endTime);
        setAppliedBookingType(bookingType);
        setAppliedBookingStatus(bookingStatus);
        handleOpenDialog(false);
    };
    const getActiveFiltersCount = () => {
        let count = 0;
        if (startTime) count++;
        if (endTime) count++;
        if (bookingType) count++;
        if (bookingStatus) count++;
        return count;
    };

    return (
        <div>
            <div className={`flex ${title === "Users" || title === "Bookings" || title === "User Groups" || title === "History" ? "justify-between items-center" : "justify-end items-center"}`}>
                <div className='flex justify-center items-center'>
                    <GlobalFilter
                        preGlobalFilteredRows={preGlobalFilteredRows}
                        globalFilter={state.globalFilter}
                        setGlobalFilter={setGlobalFilter}
                    />
                </div>
                {title === "Users" || title === "Bookings" || title === "User Groups" || title === "History" ? (
                    <div className='flex justify-end'>
                        <>
                            {/* if title is Bookings and the logged in user is a TenantAdmin of Valet, don't show the Create Bookings button */}
                            {title === "Bookings" && loggedInUser.role === "TenantAdmin" && loggedInUser.tenant === "VALET" ?
                                <Button onClick={() => setOpenComplimentary(true)} className='w-48 flex justify-center'>
                                    Complimentary
                                </Button>
                                :
                                <Button onClick={() => handleOpenDialog(true)} className='w-48 flex justify-center'>
                                    {title === "Users" ? "Invite Users" :
                                        title === "User Groups" ? "Create User Group" :
                                            title === "History" ? "Filters" :
                                                "Create Bookings"
                                    }
                                    {getActiveFiltersCount() > 0 && (
                                        <span className="absolute top-[-8px] right-0 bg-red-500 text-white text-xs rounded-full h-6 w-6 flex items-center justify-center">
                                            {getActiveFiltersCount()}
                                        </span>
                                    )}
                                </Button>
                            }
                            {title === "Users" || title === "Bookings" || title === "User Groups" ? (
                                <Sheet open={openDialog} onOpenChange={(val) => handleOpenDialog(val)} children={
                                    title === "Users" ? <InviteUsers /> :
                                        title === "User Groups" ? <CreateUserGroupBody setDialogState={(val) => setOpenDialog(val)} /> :
                                            <CreateBookingBody setDialogState={(val) => setOpenDialog(val)} />
                                } />
                            ) : null}
                            {title === "Bookings" ? (
                                <Sheet open={openComplimentary} onOpenChange={(val) => setOpenComplimentary(val)} children={<CreateComplimentaryValetBookingBody setDialogState={(val) => setOpenComplimentary(val)} />} />
                            ) : null}
                        </>
                    </div>
                ) : null}
                <Dialog open={openDialog} onOpenChange={(val: boolean) => handleOpenDialog(val)}>
                    {title === "History" ? (
                        <DialogContent>
                            <div className="flex items-center justify-center">
                                <Text as="h3">Add Filters</Text>
                            </div>

                            <div className="flex flex-row space-x-8 items-center mt-5">
                                <div className="flex flex-col">
                                    <Text as="p">Start Date</Text>
                                    <div className="mt-3">
                                        <DatePicker
                                            selected={startTime}
                                            onChange={handleStartTime}
                                            dateFormat="dd/MM/yyyy"
                                            className="date-picker bg-white mb-4 px-4 border-input items-center justify-center ring-offset-background focus:outline-blue-500 placeholder:text-muted-foreground flex h-12 w-40 rounded-md border hover:border-2 disabled:opacity-50 border-gray-400"
                                        />
                                    </div>
                                </div>
                                <div className="flex flex-col">
                                    <Text as="p">End Date</Text>
                                    <div className="mt-3">
                                        <DatePicker
                                            selected={endTime}
                                            onChange={handleEndTime}
                                            dateFormat="dd/MM/yyyy"
                                            className="date-picker mb-4 px-4 border-input items-center justify-center ring-offset-background focus:outline-blue-500 placeholder:text-muted-foreground flex h-12 w-40 rounded-md border hover:border-2 disabled:opacity-50 border-gray-400"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="flex flex-row space-x-8 items-center">
                                <div className="flex flex-col">
                                    <Text as="p">Booking Type</Text>
                                    <div className="mt-3">
                                        <Button
                                            onClick={() => handleBookingType('Single')}
                                            className={`w-44 ${bookingType === 'Single' ? 'bg-primary text-white' : 'bg-gray-200 text-gray-700'
                                                }`}
                                        >
                                            Single Booking
                                        </Button>
                                    </div>
                                </div>
                                <div className="flex flex-col">
                                    <Text as="p">&nbsp;</Text>
                                    <div className="mt-3">
                                        <Button
                                            onClick={() => handleBookingType('Multi')}
                                            className={`w-44 ${bookingType === 'Multi' ? 'bg-primary text-white' : 'bg-gray-200 text-gray-700'
                                                }`}
                                        >
                                            Multi Booking
                                        </Button>
                                    </div>
                                </div>
                            </div>
                            <div className="flex flex-row space-x-8 items-center mb-5">
                                <div className="flex flex-col mt-3 mb-10">
                                    <Text as="p">Booking Status</Text>
                                    <div >
                                        <Autocomplete
                                            disablePortal
                                            id="booking-status"
                                            options={["Rejected", "Completed", "Cancelled", "Expired"]}
                                            value={bookingStatus}
                                            onChange={(event, value) => handleBookingStatus(value!)}
                                            sx={{ width: 180 }}
                                            renderInput={(params) => <TextField {...params} label="Status" />}
                                            className='my-4 status-back-label'
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="flex justify-center space-x-4 mt-12">
                                <Button onClick={handleClearAll} variant="secondary" className="w-44 border-primary text-primaryText">Clear All</Button>
                                <Button onClick={handleApplyFilters} className="w-44 bg-primary text-white">Apply</Button>
                            </div>
                        </DialogContent>
                    ) : null}
                </Dialog>
            </div>
            <div className="mt-4 flex flex-col">
                <div className="-my-2 overflow-x-auto -mx-4 sm:-mx-6 lg:-mx-8">
                    <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                        <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                            <table {...getTableProps()} className="min-w-full divide-y divide-gray-200">
                                <thead className="bg-gray-50">
                                    {headerGroups.map(headerGroup => (
                                        <tr {...headerGroup.getHeaderGroupProps()}>
                                            {headerGroup.headers.map(column => (

                                                // Add the sorting props to control sorting. For this example
                                                // we can add them into the header props
                                                <th
                                                    scope="col"
                                                    className={`px-6 py-6 text-left text-xs font-bold text-gray-500 uppercase tracking-wider`}
                                                    style={{ width: `${column.width}px` }}
                                                    {...column.getHeaderProps(column.getSortByToggleProps())}
                                                >
                                                    <div className='flex justify-center flex-row'>
                                                        {/* <span className='mr-2'>
                                                                {column.isSorted
                                                                    ? column.isSortedDesc 
                                                                        ? ' ▼'
                                                                        : ' ▲'
                                                                    : ''}
                                                            </span> */}
                                                        {column.render('Header')}
                                                    </div>
                                                </th>
                                            ))}
                                        </tr>
                                    ))}
                                </thead>
                                <tbody
                                    {...getTableBodyProps()}
                                    className="bg-white divide-y divide-gray-200"
                                >
                                    {page.map((row, i) => {
                                        prepareRow(row)
                                        return (
                                            <tr {...row.getRowProps()}>
                                                {row.cells.map(cell => {
                                                    return (
                                                        <td
                                                            {...cell.getCellProps()}
                                                            className={`px-6 py-6 ${title === "Rules" ? "" : "whitespace-nowrap"}`}
                                                        >
                                                            {cell.render('Cell')}
                                                        </td>
                                                    )
                                                })}
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            {/* Pagination */}
            <div className='pt-6 flex items-center justify-between'>
                <div className="flex-1 flex justify-between sm:hidden">
                    <TableButton onClick={() => previousPage()} disabled={!canPreviousPage}>Previous</TableButton>
                    <TableButton onClick={() => nextPage()} disabled={!canNextPage}>Next</TableButton>
                </div>
                <div className='hidden sm:flex-1 sm:flex sm:items-center sm:justify-between'>
                    <div className="flex gap-x-6 items-center">
                        <span className="text-md text-gray-700">
                            Page <span className="font-medium text-medium ">{state.pageIndex + 1}</span> of <span className="font-medium">{pageOptions.length}</span>
                        </span>
                    </div>
                </div>
                <div>
                    <nav className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px" aria-label="Pagination">
                        <PageButton
                            className="rounded-l-md"
                            onClick={() => gotoPage(0)}
                            disabled={!canPreviousPage}
                        >
                            <span className="sr-only">First</span>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
                                <path fillRule="evenodd" d="M13.28 3.97a.75.75 0 010 1.06L6.31 12l6.97 6.97a.75.75 0 11-1.06 1.06l-7.5-7.5a.75.75 0 010-1.06l7.5-7.5a.75.75 0 011.06 0zm6 0a.75.75 0 010 1.06L12.31 12l6.97 6.97a.75.75 0 11-1.06 1.06l-7.5-7.5a.75.75 0 010-1.06l7.5-7.5a.75.75 0 011.06 0z" clipRule="evenodd" />
                            </svg>
                        </PageButton>
                        <PageButton
                            onClick={() => previousPage()}
                            disabled={!canPreviousPage}
                        >
                            <span className="sr-only">Previous</span>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
                                <path fillRule="evenodd" d="M7.72 12.53a.75.75 0 010-1.06l7.5-7.5a.75.75 0 111.06 1.06L9.31 12l6.97 6.97a.75.75 0 11-1.06 1.06l-7.5-7.5z" clipRule="evenodd" />
                            </svg>
                        </PageButton>
                        <PageButton
                            onClick={() => nextPage()}
                            disabled={!canNextPage
                            }>
                            <span className="sr-only">Next</span>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
                                <path fillRule="evenodd" d="M16.28 11.47a.75.75 0 010 1.06l-7.5 7.5a.75.75 0 01-1.06-1.06L14.69 12 7.72 5.03a.75.75 0 011.06-1.06l7.5 7.5z" clipRule="evenodd" />
                            </svg>
                        </PageButton>
                        <PageButton
                            className="rounded-r-md"
                            onClick={() => gotoPage(pageCount - 1)}
                            disabled={!canNextPage}
                        >
                            <span className="sr-only">Last</span>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
                                <path fillRule="evenodd" d="M4.72 3.97a.75.75 0 011.06 0l7.5 7.5a.75.75 0 010 1.06l-7.5 7.5a.75.75 0 01-1.06-1.06L11.69 12 4.72 5.03a.75.75 0 010-1.06zm6 0a.75.75 0 011.06 0l7.5 7.5a.75.75 0 010 1.06l-7.5 7.5a.75.75 0 11-1.06-1.06L17.69 12l-6.97-6.97a.75.75 0 010-1.06z" clipRule="evenodd" />
                            </svg>
                        </PageButton>
                    </nav>
                </div>
            </div>
        </div>
    )
}