import { addPaymentIdToBooking, BookingsData, createSingleBooking, createValetBooking, getBookingById, updateValetBooking } from "../../../utils/apis/bookings_repository";
import { Text } from "../Text"
import DatePicker from 'react-datepicker';
import { getDateTimeString, prettifyTime } from "../../../utils/helper";
import { useEffect, useState } from "react";
import { Button } from "../CustomButton";
import { DatePickerWithRange } from "../inputs/DateRangePicker";
import { SheetClose } from "../Sheets";
import { useQuery } from "@tanstack/react-query";
import { createValetUser, getAllUsers } from "../../../utils/apis/user_repository";
import { Spinner } from "../Spinner";
import { getUserFromLocalStorage, getTimeFromDate } from "../../../utils/helper";
import { Autocomplete, Divider, TextField } from "@mui/material";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { DateTime } from "luxon";
import { showToast } from "../../../utils/toast";
import { getTenant } from "../../../utils/apis/tenants_repository";
import { getTimeNowInAussie } from "../../../pages/navbar/Payment";
import axios from "axios";

interface CreateComplimentaryValetBookingBodyProps {
    setDialogState: (state: boolean) => void;
    editData?: BookingsData;
}

const CreateComplimentaryValetBookingBody = ({ setDialogState, editData }: CreateComplimentaryValetBookingBodyProps) => {

    // sort in alphabetical order
    const venues = ["12 Micron", "Callao", "Meat & Wine Co", "Nola", "The Butcher's Block"]
    const currentUser = getUserFromLocalStorage();
    const allowedDaysStr = process.env.REACT_APP_ALLOWED_DAYS || "5,6";
    const allowedDays = allowedDaysStr.split(",").map((day) => parseInt(day));
    const queryClient = useQueryClient();
    const { data: tenantData } = useQuery({
        queryKey: ['tenantData'],
        queryFn: () => getTenant(currentUser.tenant),
    })
    const getMinDate = () => {
        const dateTimeNowAustie = getTimeNowInAussie();
        const day = dateTimeNowAustie.getDay();
        if (allowedDays.includes(day)) {
            return dateTimeNowAustie;
        } else {
            const nextAllowedDay = allowedDays.find((allowedDay) => allowedDay > day) ?? allowedDays[0];
            const diff = nextAllowedDay - day;
            if (diff < 0) {
                const nextDate = DateTime.fromJSDate(dateTimeNowAustie).plus({ days: 7 + diff }).toJSDate();
                return nextDate;
            } else {
                const nextDate = DateTime.fromJSDate(dateTimeNowAustie).plus({ days: diff }).toJSDate();
                return nextDate;
            }
        }
    }

    const { data: fetchedBooking } = useQuery({
        queryKey: ['booking', editData?.bookingId],
        queryFn: () => getBookingById(editData?.bookingId ?? null),
    });

    // tenantData can be undefined
    // tenantData.fulldayDefaultTimes.startHour = 0800. we need to return only 8
    const tenantStartHour = tenantData?.fulldayDefaultTimes.startHour ? tenantData.fulldayDefaultTimes.startHour / 100 : 9;
    const tenantEndHour = tenantData?.fulldayDefaultTimes.endHour ? tenantData.fulldayDefaultTimes.endHour / 100 : 18;
    const prettyStartHour = prettifyTime(tenantData?.fulldayDefaultTimes.startHour ? tenantData?.fulldayDefaultTimes.startHour : 800);
    const prettyEndHour = prettifyTime(tenantData?.fulldayDefaultTimes.endHour ? tenantData?.fulldayDefaultTimes.endHour : 1800);
    const startTime = DateTime.now().hour < tenantStartHour ? prettyStartHour : getTimeFromDate(new Date())
    const endTime = DateTime.now().hour < tenantEndHour ? prettyEndHour : getTimeFromDate(DateTime.now().plus({ hours: 3 }).toJSDate())

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [enableSheetInput, setEnableSheetInput] = useState<boolean>(false);
    const [isDateAvailable, setIsDateAvailable] = useState<boolean>(true);
    const [startDate, setStartDate] = useState<Date | null>(getMinDate());
    const [plateNumber, setPlateNumber] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [name, setName] = useState<string>('');
    const [phone, setPhone] = useState<string>('');
    const [venue, setVenue] = useState<string>('');


    useEffect(() => {
        const day = DateTime.now().weekday;
        if (!allowedDays.includes(day)) {
            const nextAllowedDay = allowedDays.find((allowedDay) => allowedDay > day) ?? allowedDays[0];
            const diff = nextAllowedDay - day;
            if (diff < 0) {
                setStartDate(DateTime.now().setZone('Australia/Sydney').plus({ days: 7 + diff }).toJSDate());
            } else {
                setStartDate(DateTime.now().setZone('Australia/Sydney').plus({ days: diff }).toJSDate());
            }
        }
    }, []);


    // if EditData is passed, set the values
    useEffect(() => {
        if (editData && fetchedBooking) {
            // split by the last : and get the first part
            //ie: 2024-11-20T16:35:000Z becomes 2024-11-20T16:35
            const fetchedFormattedDate = fetchedBooking.startTime.split(':').slice(0, -1).join(':');
            setStartDate(new Date(fetchedFormattedDate));
            setPlateNumber(fetchedBooking.plate[0] ?? null);
            setEmail(fetchedBooking.email);
            setName(fetchedBooking.name);
            setPhone(fetchedBooking.phone ?? '');
            setVenue(fetchedBooking.venue);
        }
    }, [editData, fetchedBooking]);

    useEffect(() => {
        if (startDate) {
            handleDateChange(startDate);
        }
    }, [startDate])

    const handlePhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value.replace(/\D/g, '');
        setPhone(newValue);
    };

    const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value.trim().toLowerCase();
        setEmail(newValue);
    };

    const formatDateForPayload = (date: Date): string => {
        // Convert the date to UTC to prevent timezone issues
        const utcDate = new Date(Date.UTC(
            date.getFullYear(),
            date.getMonth(),
            date.getDate(),
            date.getHours(),
            date.getMinutes(),
            date.getSeconds()
        ));

        const datePart = utcDate.toISOString().split('T')[0]; // Extract date in YYYY-MM-DD format
        const timePart = '00:00:00.000'; // Ensure time is midnight in UTC
        return `${datePart}T${timePart}Z`; // Combine date and time
    };

    const checkDateAvailability = async (date: any): Promise<boolean> => {
        const formattedDate = formatDateForPayload(date);
        try {

            const response = await axios.post(`${process.env.REACT_APP_TENANT_OCCUPANCY_URL}`, {
                date: formattedDate,
            });
            return response.data.status === true;
        } catch (error) {
            console.error('Error fetching date availability', error);
            return false;
        }
    };

    const handleDateChange = async (date: Date): Promise<void> => {
        setIsLoading(true);
        setEnableSheetInput(false);
        setStartDate(date);
        const isAvailable = await checkDateAvailability(date);
        setIsDateAvailable(isAvailable);
        setIsLoading(false);
        setEnableSheetInput(true);
    };

    const handleCreateBooking = async () => {

        setIsLoading(true);

        const now = DateTime.now();

        if (!venue) {
            showToast("Venue is empty", {
                type: "warning"
            });
            setIsLoading(false);
            return;
        }

        if (!startDate) {
            showToast("Start date is empty", {
                type: "warning"
            });
            setIsLoading(false);
            return;
        }

        if (!name) {
            showToast("Name is empty", {
                type: "warning"
            });
            setIsLoading(false);
            return;
        }

        if (!email) {
            showToast("Email is empty", {
                type: "warning"
            });
            setIsLoading(false);
            return;
        }

        if (!phone) {
            showToast("Phone Number is empty", {
                type: "warning"
            });
            setIsLoading(false);
            return;
        }

        const updatedStartDate = getDateTimeString(startDate!.toISOString(), startTime!);
        const updatedEndDate = getDateTimeString(startDate!.toISOString(), endTime!);
        

        //2024-11-20T16:35:000Z pass only the date  part and get a dateTime object of ausie 
        const ausieDateTime = DateTime.fromISO(updatedStartDate.split('T')[0]).setZone('Australia/Sydney');

        if (DateTime.fromJSDate(new Date(updatedStartDate)) < now) {
            showToast("Start time cannot be in the past", {
                type: "warning"
            });
            return;
        }

        const valetUserId = await createValetUser({
            email: email,
            name: name,
            cardholderId: '',
            gallagherId: '',
            vehicles: [plateNumber],
            role: '',
            tenant: '',
            permanentParking: false,
            userId: '',
            bookingLimit: 0,
            futureBookingPeriod: 0,
            lastMinuteBookingPeriod: 0,
        });

        const boookingData = {
            userId: valetUserId,
            name: name,
            plateNumber: plateNumber,
            startTime: ausieDateTime,
            endTime: ausieDateTime,
            email: email,
            phone: phone,
            venue: venue,
        }

        if (!editData) {
            const { bookingId, isApproved } = await createValetBooking(boookingData);

            if (isApproved && bookingId) {
                await addPaymentIdToBooking(bookingId, 'complimentary', venue, plateNumber);
            }
        } else {
            const editedBookingData = {
                startTime: updatedStartDate,
                venue: venue,
                email: email,
                plateNumber: plateNumber,
                phone: phone,
                bookingId: editData.bookingId,
                paymentId: editData.paymentId,
            }
            await updateValetBooking(editedBookingData);
        }

        await queryClient.invalidateQueries({ queryKey: ['bookings'] });
        setIsLoading(false);
        setDialogState(false);
    }

    const userData = getUserFromLocalStorage();

    const { data: allUsers, isLoading: dataLoading, isError } = useQuery({
        queryKey: ['users'],
        queryFn: () => getAllUsers(
            userData.role === "SuperAdmin" ? undefined : userData.tenant
        ),
    })

    if (isError) {
        return <div className="flex justify-center">Something went wrong</div>
    }

    return (
        <>
            {dataLoading ? <div className="flex items-center flex-col justify-center">
                <Text as="span">Getting all User Details, Please wait</Text>
                <div className="mt-4">
                    <Spinner />
                </div>
            </div> : <div>
                {!editData && (
                    <>
                        <div className="flex pt-10 flex-row items-center justify-between">
                            <Text className="ml-10" as="h2">Create New Booking</Text>
                        </div>
                        <div className="pt-4">
                            <Divider className="bg-secondaryText" />
                        </div>
                    </>
                )}

                <div className="flex flex-col items-start">
                    <Text className="ml-10 mb-1" as="h3">Booking Venue</Text>
                    <div>
                        <select
                            value={venue}
                            onChange={(e) => setVenue(e.target.value)}
                            className="ml-10 border-input ring-offset-background focus:outline-blue-500 placeholder:text-muted-foreground h-12 w-[280px] rounded-md border hover:border-2 focus-visible:outline-blue-500 bg-transparent px-4 py-2 text-md disabled:cursor-not-allowed disabled:opacity-50 border-gray-400"
                        >
                            <option value="" disabled>Select a venue</option>
                            {venues.map((venue) => (
                                <option key={venue} value={venue}>
                                    {venue}
                                </option>
                            ))}
                        </select>
                    </div>

                    <Text className="ml-10 mb-1" as="h3">Booking Date</Text>
                    <div className="ml-10">
                        <DatePicker
                            minDate={getMinDate()}
                            selected={startDate}
                            onChange={handleDateChange}
                            dateFormat="dd/MM/yyyy"
                            className="date-picker mb-1 px-4 border-input items-center justify-center ring-offset-background focus:outline-blue-500 placeholder:text-muted-foreground flex h-12 w-[280px] rounded-md border hover:border-2 disabled:opacity-50 border-gray-400"
                            filterDate={(date) => {
                                const day = date.getDay();
                                return allowedDays.includes(day);
                            }}
                            placeholderText={getTimeNowInAussie().getDay() === 5 || getTimeNowInAussie().getDay() === 6 ? "Select a Date" : "Date*"}
                        />

                    </div>
                    {isDateAvailable && enableSheetInput && (
                        <>
                            <Text className="ml-10 mb-1 mt-6" as="h3">Name</Text>
                            <input
                                className="ml-10 date-picker mb-1 px-4 border-input items-center justify-center ring-offset-background focus:outline-blue-500 placeholder:text-muted-foreground flex h-12 w-[280px] rounded-md border hover:border-2 disabled:opacity-50 border-gray-400"
                                type="text"
                                placeholder="Name*"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                disabled={editData ? true : false}
                                required
                            />

                            <Text className="mt-6 ml-10 mb-1" as="h3">Email</Text>
                            <input
                                className="ml-10 date-picker mb-1 px-4 border-input items-center justify-center ring-offset-background focus:outline-blue-500 placeholder:text-muted-foreground flex h-12 w-[280px] rounded-md border hover:border-2 disabled:opacity-50 border-gray-400"
                                type="email"
                                placeholder="Email*"
                                value={email}
                                onChange={handleEmailChange}
                                disabled={editData ? true : false}
                                required
                            />

                            <Text className="mt-6 ml-10 mb-1" as="h3">Phone</Text>
                            <input
                                className="ml-10 date-picker mb-1 px-4 border-input items-center justify-center ring-offset-background focus:outline-blue-500 placeholder:text-muted-foreground flex h-12 w-[280px] rounded-md border hover:border-2 disabled:opacity-50 border-gray-400"
                                type="tel"
                                placeholder="Phone*"
                                value={phone}
                                onChange={handlePhoneChange}
                                maxLength={10}
                                required
                            />
                            <Text className="mt-6 ml-10 mb-1" as="h3">Plate Number</Text>
                            <input
                                className="ml-10 date-picker mb-1 px-4 border-input items-center justify-center ring-offset-background focus:outline-blue-500 placeholder:text-muted-foreground flex h-12 w-[280px] rounded-md border hover:border-2 disabled:opacity-50 border-gray-400"
                                type="text"
                                placeholder="Plate Number"
                                value={plateNumber}
                                onChange={(e) => {
                                    const filteredValue = e.target.value.replace(/[^a-zA-Z0-9]/g, '').toUpperCase();
                                    setPlateNumber(filteredValue);
                                }}
                                pattern="[A-Za-z0-9]*"
                            />
                        </>
                    )}

                    {!isDateAvailable && <p className="error-message mt-2 text-primary">Selected date is occupied. Please choose another date.</p>}
                </div>
                <div className="absolute bottom-0 mx-12 right-0 mb-10 flex flex-row justify-end">
                    <SheetClose asChild>
                        <Button variant={"secondary"} className="w-44 border border-primary text-primaryText">Cancel</Button>
                    </SheetClose>
                    <Button variant={"default"} onClick={handleCreateBooking} isLoading={isLoading} className="w-44 ml-4 text-white">Save Changes</Button>
                </div>
            </div>}
        </>
    );
}

export default CreateComplimentaryValetBookingBody;