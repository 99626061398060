import React, { useState, useEffect, useRef } from 'react';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { addPaymentIdToBooking, deleteValetBooking } from '../../../utils/apis/bookings_repository';
import { useNavigate } from 'react-router-dom';
import { AddToCalendarButton } from 'add-to-calendar-button-react';
import vip_logo from "../../../assets/images/vip_logo.png";
import { Text } from '../../../components/ui/Text';
import { confirmAlert } from 'react-confirm-alert';
import { MdDirections } from 'react-icons/md';
import { FaApple } from 'react-icons/fa';
import { createPaymentIntent, createStripeCustomer, handleValetCancelBooking } from '../../../utils/apis/payment_repository';
import { Dialog, DialogContent } from '../Dialog';
import { Button } from '../CustomButton';
import TermsModal from '../modals/TermsModal';
import { showToast } from '../../../utils/toast';

interface PaymentFormProps {
    selectedDate: Date | string;
    venue: string;
    phone: string;
    plateNumber: string;
    email: string;
    bookingId: string;
    status: boolean;
    startHour: string;
    endHour: string;
}

const PaymentForm: React.FC<PaymentFormProps> = ({ selectedDate, venue, phone, plateNumber, email, bookingId, status, startHour, endHour }: PaymentFormProps) => {
    const [initialPaymentSuccess, setInitialPaymentSuccess] = useState(false);
    const [singleDialog, openSingleDialog] = useState(false);
    const [refundSuccess, setRefundSuccess] = useState(false);
    const [customerId, setCustomerId] = useState<string | null>(null);
    const [paymentIntentId, setPaymentIntentId] = useState<string | null>(null);
    const [savePaymentMethod, setSavePaymentMethod] = useState(false);
    const [amount, setAmount] = useState(0);
    const [paymentCompleted, setPaymentCompleted] = useState(false);
    const [paymentLoading, setPaymentloading] = useState<boolean>(false);
    const [timeLeft, setTimeLeft] = useState(15 * 60); // 15 minutes in seconds
    const [timeIsUp, setTimeIsUp] = useState(false);
    const [paymentDetailsValid, setPaymentDetailsValid] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [couponCode, setCouponCode] = useState("");
    const [couponName, setCouponName] = useState("");
const [couponValid, setCouponValid] = useState(false);
const [discountAmount, setDiscountAmount] = useState(0);
const [couponError, setCouponError] = useState("");
const handleCouponValidation = async () => {
    setCouponError("");  // Reset coupon error message
    if (!couponCode) {
        setCouponError("Please enter a coupon code.");
        return;
    }
    const stripeSecretKey = process.env.REACT_APP_STRIPE_SECRET_KEY;

    try {
        const response = await fetch('https://api.stripe.com/v1/coupons', {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${stripeSecretKey}`,
                'Content-Type': 'application/x-www-form-urlencoded',
            },
        });
        const data = await response.json();

        if (response.ok && data.data.length) {
            // Find the coupon that matches the input coupon code
            const coupon = data.data.find((c: { id: string; }) => c.id === couponCode.toUpperCase());

            if (coupon && coupon.valid) {
                setCouponValid(true);
                setCouponName(coupon.name);
                let discountAmount = coupon.percent_off ? 
                    (amount * coupon.percent_off / 100) : 
                    (coupon.amount_off / 100); 
                setDiscountAmount(discountAmount);
                setAmount(prevAmount => Math.max(0, prevAmount - discountAmount)); 
            } else {
                setCouponValid(false);
                setDiscountAmount(0);
                setCouponError("Invalid coupon code.");
            }
        } else {
            throw new Error("Failed to retrieve coupons or no coupons available");
        }
    } catch (error) {
        console.error("Failed to validate coupon:", error);
        setCouponError("Error validating coupon. Try again.");
    }
};


    
    const openModal = () => setIsModalOpen(true);
    const closeModal = () => setIsModalOpen(false);
  
    const stripe = useStripe();
    const elements = useElements();
    const navigate = useNavigate();

    const timerRef = useRef<number | null>(null);
    const endTimeRef = useRef<Date | null>(null);

    const formattedDate = new Date(selectedDate).toLocaleDateString();
    const formattedTime = new Date(selectedDate).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    const [isSingleDeleteLoading, setIsSingleDeleteLoading] = useState<boolean>(false);

    const googleMapsUrl = process.env.REACT_APP_GOOGLE_MAPS_URL;
    const appleMapsUrl = process.env.REACT_APP_APPLE_MAPS_URL;
    useEffect(() => {
        switch (venue) {
            case '12 Micron':
            case 'Meat & Wine Co':
            case 'Nola':
            case 'Callao':
            case "The Butcher's Block":
                setAmount(80);
                break;
            default:
                setAmount(0);
                break;
        }
    }, [venue]);
 
    useEffect(() => {
        if (paymentCompleted) return;

        const timerStartDelay = setTimeout(() => {
            const startTime = new Date();
            endTimeRef.current = new Date(startTime.getTime() + 15 * 60 * 1000); // 15 minutes later

            timerRef.current = window.setInterval(() => {
                const currentTime = new Date();
                const remainingTime = Math.max(0, Math.floor((endTimeRef.current!.getTime() - currentTime.getTime()) / 1000));
                setTimeLeft(remainingTime);

                if (remainingTime <= 0) {
                    clearInterval(timerRef.current!);
                    setTimeIsUp(true);
                    alert('Time is up! Your booking will be canceled.');
                    handleCancelBooking();
                }
            }, 1000);
        }, 3000); // Start timer after 3 seconds

        return () => {
            clearTimeout(timerStartDelay); // Clean up the timeout if the component unmounts before the delay
            if (timerRef.current) {
                clearInterval(timerRef.current);
            }
        };
    }, [paymentCompleted]);


    useEffect(() => {
        const handleTabClose = (event: { preventDefault: () => void; returnValue: string; }) => {
          event.preventDefault();
    
          console.log('beforeunload event triggered');
    
          return (event.returnValue =
            'Are you sure you want to exit?');
        };
    
        window.addEventListener('beforeunload', handleTabClose);
    
        return () => {
          window.removeEventListener('beforeunload', handleTabClose);
        };
      }, []);

    const formatTime = (seconds: number) => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
    };
    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();

        setPaymentloading(true);
        if (!stripe || !elements) {
            return;
        }

        const cardElement = elements.getElement(CardElement);
        if (!cardElement) {
            return;
        }

        const { error, paymentMethod } = await stripe.createPaymentMethod({
            type: 'card',
            card: cardElement,
        });

        if (error) {
            showToast(error.message!, { type: 'error' });
            console.error("Error creating payment method:", error);
            setPaymentloading(false);
            return;
        }

        try {
            const { id } = paymentMethod;

            // Call the new function to create a customer
            const customer = await createStripeCustomer(id, email, phone);
            setCustomerId(customer.id);

            const bookingIdLast4 = bookingId.slice(-4);

            try {
                // Call the new function to create a payment intent
                const paymentIntent = await createPaymentIntent(
                    customer.id,
                    amount,
                    id,
                    `Initial fee for vehicle ${plateNumber} at ${venue} on ${selectedDate} bookingID ${bookingIdLast4}`
                );

                if (paymentIntent && paymentIntent.status === 'succeeded') {
                    setInitialPaymentSuccess(true);
                    setPaymentIntentId(paymentIntent.id);
                    setPaymentCompleted(true);

                    const success = await addPaymentIdToBooking(bookingId, paymentIntent.id, customer.id, plateNumber);
                    if (success) {
                        console.log("Successfully added Stripe ID to booking");
                    } else {
                        console.log("Failed adding Stripe ID to booking");
                    }
                } else {
                    // Handle payment failure
                    // await deleteValetBooking(bookingId);
                    console.error("Payment failed");
                    // navigate("/valet");
                }
                setPaymentloading(false);
            } catch (paymentError) {
                // Delete the booking if payment fails
                // await deleteValetBooking(bookingId);
                console.error("Error processing payment:", paymentError);
                setPaymentloading(false);
                // navigate("/valet");
            }

        } catch (err: any) {
            console.error("Error processing payment:", err);
            showToast(err?.response?.data.error.message, { type: 'error' });
            // navigate("/valet");
            setPaymentloading(false);
        }
    };
    
    const handleCreateBooking = async () => {
        navigate("/valet");
        window.location.reload();
    };

    const handleEditBooking = async () => {
        navigate(`/valet/edit?bookingId=${bookingId}`);
    };

    const handleCancelBooking = async () => {
        if (paymentCompleted) {
            try {
                setIsSingleDeleteLoading(true);
                const isCancelSuccess = await handleValetCancelBooking(bookingId!);

                if (isCancelSuccess) {
                    setRefundSuccess(true);
                    // navigate("/valet")
                }

            } catch (error) {
                console.error('Error canceling booking:', error);
            }
            setIsSingleDeleteLoading(false);

        }
        else {
            setIsSingleDeleteLoading(true);

            await deleteValetBooking(bookingId!);
            setRefundSuccess(true);
            setIsSingleDeleteLoading(false);

            window.location.reload();
        }
        openSingleDialog(false);
    };

    const cardElementOptions = {
        hidePostalCode: true,
        style: {
            base: {
                fontSize: '16px',
                color: '#32325d',
                '::placeholder': {
                    color: '#aab7c4',
                },
            },
            invalid: {
                color: '#fa755a',
            },
        },
    };

    const openInGoogleMaps = () => {
        const url = `${googleMapsUrl}${-33.86336531482488},${151.20226835295642}`;
        window.open(url, '_blank');
    };

    const openInAppleMaps = () => {
        const url = `${appleMapsUrl}${-33.86336531482488},${151.20226835295642}`;
        window.open(url, '_blank');
    };
    const handleCardChange = (event: { complete: boolean | ((prevState: boolean) => boolean); }) => {
        setPaymentDetailsValid(event.complete);
    };

    return (
        <div className="valetCard flex flex-col items-center">

            {!initialPaymentSuccess ? (
                <form onSubmit={handleSubmit} className="w-full max-w-md">
                    <img src={vip_logo} style={{ height: '150px' }} />
                    <Text as="h2" className="text-xl  justify-center font-bold text-gray-600 mt-0 mb-4">
                        Payment Details
                    </Text>
                    <div className="w-full max-w-md mb-4 p-4 border rounded-md shadow-sm bg-white">
                        <p className="text-gray-600"><strong>Venue:</strong> {venue}</p>
                        <p className="text-gray-600"><strong>Date:</strong> {formattedDate}</p>
                        <p className="text-gray-600"><strong>Time:</strong> {startHour} to {endHour}</p>
                        <p className="text-gray-600"><strong>Amount:</strong> ${amount}</p>
                        <p className="text-gray-600"><strong>Plate Number:</strong> {plateNumber}</p>
                        <p className="text-gray-600"><strong>Email:</strong> {email}</p>
                        <p className="text-gray-600"><strong>Phone:</strong> {phone}</p>
                    </div>
                    <fieldset className="FormGroup">
    <div className="FormRow">
        <CardElement options={cardElementOptions} onChange={handleCardChange} />
    </div>
    <div className="FormRow flex items-center justify-between mt-4 mb-4">
        <input
            type="text"
            placeholder="Enter coupon code"
            value={couponCode}
            onChange={(e) => {
                setCouponCode(e.target.value);
                // Reset validation state if the user alters the coupon code
                if (couponValid || couponError) {
                    setCouponValid(false);
                    setCouponError('');
                }
            }}
            disabled={couponValid}
            className="input-field flex-grow mr-4 h-12 px-4 border rounded-md focus:outline-blue-500 placeholder:text-muted-foreground disabled:opacity-50 border-gray-400 hover:border-blue-300"
        />
        <button
            type="button"
            onClick={handleCouponValidation}
            className={`button-field flex-none w-48 h-12 px-4 text-white font-medium rounded-md ${
                !couponValid && !couponError
                    ? 'bg-primary hover:bg-primary-dark'
                    : couponValid
                    ? 'bg-green-500 hover:bg-green-600'
                    : 'bg-red-500 hover:bg-red-600'
            } disabled:cursor-not-allowed disabled:opacity-50`}
            disabled={!couponCode.trim() || couponValid}
        >
            {couponValid ? 'Coupon Valid' : couponError ? 'Invalid Coupon' : 'Apply Coupon'}
        </button>
    </div>
    {couponError && !couponValid && <p className="text-red-500">{couponError}</p>}
    {couponValid && (
        <>
             <div className="coupon-box">
            <span><strong>{couponCode}</strong> is applied</span>
            <span className="coupon-desc">{couponName}</span>
        </div>
            <div className="Summary">
                <p>Original Amount: <span className="amount">$80.00</span></p>
                <p>Discounts: <span className="amount">-${discountAmount.toFixed(2)}</span></p>
                <hr className="summary-divider" />
                <p>Total: <span className="amount">${(amount).toFixed(2)}</span></p>
            </div>
        </>
    )} <div className="FormRow mt-3">
        <label className="flex items-center">
            <input
                type="checkbox"
                checked={savePaymentMethod}
                onChange={(e) => setSavePaymentMethod(e.target.checked)}
            />{' '}
            &nbsp;
            <span
                onClick={openModal}
                style={{ cursor: 'pointer', color: '#007bff', textDecoration: 'underline' }}
            >
                Agree to terms and conditions
            </span>
        </label>
        {isModalOpen && <TermsModal closeModal={closeModal} />}
    </div>
</fieldset>


                    <button disabled={timeIsUp || !paymentDetailsValid || !savePaymentMethod} type="submit" className="w-48 flex justify-center bg-primary h-12 py-2 px-4 text-white font-medium rounded-md disabled:cursor-not-allowed disabled:opacity-50 items-center justify-center mt-2">
                        {paymentLoading ? (
                            <svg className="animate-spin h-5 w-5 mr-3 text-white" viewBox="0 0 24 24">
                                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8v8z"></path>
                            </svg>
                        ) : (
                            "Pay"
                        )}
                    </button>
                    <div className="flex justify-center mt-2 mb-1">
                        <span className="font-bold text-gray-400">{`Time left: ${formatTime(timeLeft)}`}</span>
                    </div>
                </form>
            ) : (
                <div >
                    <img src={vip_logo} alt="Success" style={{ height: '150px' }} className="text-green-600 mx-auto" />
                    {!refundSuccess ? <h2 className="success-message text-2xl font-bold text-green-600 text-center mb-2">
                        Your booking has been placed successfully!
                    </h2> : <h2 className="success-message text-2xl font-bold text-green-600 text-center mb-2">
                        Your booking has been cancelled successfully!
                    </h2>}
                    {!refundSuccess ?
                      <div>  <div className="w-full max-w-md mb-4 p-4 border rounded-md shadow-sm bg-white">
                            <p className="text-gray-600"><strong>Email:</strong>  {email}</p>
                            <p className="text-gray-600"><strong>Phone: </strong> {phone}</p>
                            <p className="text-gray-600"><strong>Plate Number:</strong>  {plateNumber}</p>
                            <p className="text-gray-600"><strong>Venue:</strong>  {venue}</p>
                            <p className="text-gray-600"><strong>Date: </strong> {formattedDate}</p>
                            <p className="text-gray-600"><strong>Time: </strong> {startHour} to {endHour}</p>
                            <p className="text-gray-600"><strong>Amount:</strong>  ${amount}</p>
                            </div> <div className="flex justify-center">
                               
                         <AddToCalendarButton
                                    hideBranding={true}
                                    name={`Booking at ${venue}`}
                                    options={['Google', 'iCal', 'Outlook.com', 'Microsoft365', 'Apple', 'Yahoo']}
                                    location={venue}
                                    description={`Booking Details:\n\nVenue: ${venue}\nDate: ${formattedDate}\nTime: 5 pm till midnight\nAmount Paid: $${amount}\nPhone: ${phone}\nEmail: ${email}`}
                                    startDate={new Date(new Date(selectedDate).toLocaleString('en-US', { timeZone: 'Australia/Sydney' })).toISOString().slice(0, 10)}  // Adjust to Sydney time zone
                                    endDate={new Date(new Date(selectedDate).toLocaleString('en-US', { timeZone: 'Australia/Sydney' })).toISOString().slice(0, 10)}    // Adjust to Sydney time zone
                                    startTime='17:30'
                                    endTime='23:30'    
                                    timeZone='Australia/Sydney'
                                ></AddToCalendarButton>
                            </div>
                            <div className="mt-4 flex space-x-4 justify-center">
                                <button
                                    className="flex items-center justify-center bg-blue-500 text-white px-4 py-2 rounded-md"
                                    onClick={openInGoogleMaps}
                                >
                                    <MdDirections className="mr-2" /> Google Maps
                                </button>
                                <button
                                    className="flex items-center justify-center bg-gray-300 text-gray-700 px-4 py-2 rounded-md"
                                    onClick={openInAppleMaps}
                                >
                                    <FaApple className="mr-2" /> Apple Maps
                                </button>
                           
                        </div></div> : <></>}

                    {!refundSuccess && status ? (
                        <div className="flex space-x-2 mt-4 justify-center">
                            <button
                                type="button"
                                onClick={handleEditBooking}
                                className="w-40 sm:w-46 md:w-48 flex justify-center items-center bg-primary h-12 py-2 px-4 text-white font-medium rounded-md disabled:cursor-not-allowed disabled:opacity-50 text-base"
                            >
                                Edit Booking
                            </button>

                            <Dialog open={singleDialog} onOpenChange={(val) => openSingleDialog(val)}>
                        <Button className="bg-delete w-44 text-base" onClick={() => openSingleDialog(true)} isLoading={isSingleDeleteLoading}>Cancel Booking</Button>
                       
                        <DialogContent>
                            <Text as="h3" className="text-center">Delete Booking</Text>
                            <Text as="span" className="text-center">Are you sure you want to delete this booking? Deleting this booking will result in the user not being able to enter the carpark</Text>
                            <div className="flex flex-row justify-center space-x-4 mt-6">
                                <Button onClick={() => openSingleDialog(false)} variant="secondary" className="w-44 border-primary text-primaryText">Cancel</Button>
                                <Button onClick={handleCancelBooking} className="w-44 bg-delete text-white" isLoading={isSingleDeleteLoading}>Delete</Button>
                            </div>
                        </DialogContent>
                    </Dialog>
                        </div>



                    ) : (
                        <>
                            <h2 className="text-gray-500 text-center">
                                ${amount} has been refunded to your account.
                            </h2>
                            <div className="flex space-x-4 mt-4 justify-center">
                                <button
                                    type="button"
                                    onClick={handleCreateBooking}
                                    className="w-48 flex justify-center bg-primary h-12 py-2 px-4 text-white font-medium rounded-md disabled:cursor-not-allowed disabled:opacity-50"
                                >
                                    Create Booking
                                </button>
                            </div>
                        </>
                    )}

                </div>
            )}
            
        </div>
    );
}

export default PaymentForm;



